import React from "react";
import styled from "styled-components";

import { ContentWrapper, H2, GreyBg, ButtonLink } from "../assets/styles/base";
import { colors } from "../constants/colors";
import { breakpoints } from "../constants/breakpoints";
import DesktopImage from "../assets/icons/process_web_1114x210.svg";
import DesktopBottomIcons from "../assets/icons/time_money_144x72_web.svg";
import MobileBottomIcons from "../assets/icons/time_money_144x72_mob.svg";
import MobileImage from "../assets/icons/process_mob_117x458.svg";
import { urls, extendUrlWithSourceVersion } from "../constants/urls";

const ProcessExplanationShort = ({ hasGreyBg, hasMarginTop }) => {
    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop} id="process-explanation-short">
            <H2 center>Wie kommst du zu deinem&nbsp;Kredit?</H2>

            <Wrapper>
                <DesktopVersion className="processShort" height={"auto"} />
                <MobileVersion className="processShort" height={626} />
                <ul>
                    <li>
                        Immobilie
                        <br />
                        gefunden
                    </li>
                    <li>
                        Beratung durch
                        <br />
                        Finanzierungs<span>-</span>spezialist:in
                    </li>
                    <li>
                        Individuelles
                        <br />
                        Bankangebot
                    </li>
                    <li>
                        Kreditvertrag
                        <br />
                        unterschreiben
                    </li>
                </ul>
            </Wrapper>
            <BottomTextMobile className="bottomText">
                Mit&nbsp;<strong className="color-primary">miracl</strong>&nbsp;sparst du Zeit und Geld
                <MobileBottomIcons height={72} />
            </BottomTextMobile>
            <BottomTextDesktop className="bottomText">
                <span>
                    Mit&nbsp;<strong className="color-primary">miracl</strong>&nbsp;sparst du
                </span>
                <DesktopBottomIcons width={144} height={72} />
                <span>Zeit und Geld</span>
            </BottomTextDesktop>
            <CtaWrapper>
                <ButtonLink
                    background="#2563EB"
                    href={extendUrlWithSourceVersion(urls.survey)}
                    size="lg"
                    borderRadius={38}
                    px={4}
                    style={{ fontSize: 16 }}
                >
                    Beratung anfragen
                </ButtonLink>
            </CtaWrapper>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const StyledContentWrapper = styled(ContentWrapper)`
    color: ${colors.primary};
    margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "50px" : "0")};

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "100px" : "0")};
    }
`;

const DesktopVersion = styled(DesktopImage)`
    display: none;

    @media (min-width: ${breakpoints.tabletMin}) {
        display: inline-block;
    }
`;

const MobileVersion = styled(MobileImage)`
    display: block;

    @media (min-width: ${breakpoints.tabletMin}) {
        display: none;
    }
`;

const BottomTextMobile = styled.p`
    color: #282828;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0 0;
    padding: 0 5px;

    svg {
        margin-top: 15px;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: none;
    }
`;
const BottomTextDesktop = styled.p`
    color: #282828;
    align-items: center;
    display: none;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 30px 0 0;
    padding: 0 5px;

    span {
        display: inline-block;
        white-space: nowrap;
        width: 150px;

        &:first-child {
            text-align: right;
        }
    }
    svg {
        margin: -2px 15px 0 15px;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: flex;
    }
`;

const Wrapper = styled.div`
    color: #282828;
    font-size: 14px;
    line-height: 18px;
    position: relative;

    .processShort {
        margin: 0;
        right: 43%;
        position: absolute;
        width: 160px;
    }
    svg {
        width: 100%;
    }
    ul {
        list-style: none;
        margin: 0 0 0 59%;
        padding: 21px 0 40px;
    }

    li {
        height: 180px;

        &:last-child {
            height: auto;
        }
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;
        text-align: center;

        .processShort {
            margin: 0 10%;
            position: static;
            width: 80%;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding: 0;
            width: 86%;
        }
        li {
            height: auto;
            width: 19%;
        }
        ~ .bottomText {
            margin: 74px 0 0;
        }
    }

    @media (min-width: 1260px) {
        li {
            span {
                display: none;
            }
        }
        ~ .bottomText {
            img {
                height: 108px;
                margin-top: -4px;
            }
        }
    }

    @media (min-width: ${breakpoints.tabletMin}) and (max-width: 850px) {
        font-size: 13px;
        line-height: 20px;
        margin-top: 20px;

        ~ .bottomText {
            margin-top: 40px;
        }

        span {
            display: inline;
        }
    }

    @media (min-width: ${breakpoints.tabletMin}) and (max-width: 720px) {
        font-size: 12px;
        line-height: 16px;

        .processShort {
            margin-left: 0;
            margin-right: 0;
            width: 88%;
        }
        ul {
            width: 100%;
        }

        li {
            width: 23%;
        }
        ~ .bottomText {
            svg {
                height: 72px;
            }
        }
    }
`;

const CtaWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.5rem;
`;

export default ProcessExplanationShort;
